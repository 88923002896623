import "@scss/main.scss"
import Cookizi from './libs/Cookizi'

window.onload = function(){
	// Cookizi
    const cookizi = new Cookizi({
        types: ['analytics', 'custom', 'marketing']
	})
	if(document.querySelector('#block-block-1')) {
		document.querySelector('.btn-cookie').addEventListener('click', e => {
			e.preventDefault()
			cookizi.showSidebar()
		})
	}

}

// SWPL LOG
console.log(
	"%c S W P L ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`,
	" www.swpl.fr"
);
